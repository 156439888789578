import { Suspense, lazy } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/styles/tailwind.min.css';
import './assets/styles/index.css';
import './assets/styles/docs.css';
// Docs Routes - START - you can delete these when you no longer need our docs

import ReactLoader from './components/loader';
import * as ROUTES from './constants/routes';
import CartContext from './mycomponent/cartContext';
import useShopingCart from './mycomponent/shoppingCart';
import useUser from './mycomponent/useUser';
import useAdmin from './mycomponent/useAdmin';
import useLanguage from './mycomponent/useLanguage';
import useDevise from './mycomponent/useDevise';
import useFosa from './mycomponent/useFosa';

// Product Pages - STOP
// Docs Routes -  STOP

// Product Pages - START
// // admin

const CategoryBesoin = lazy(() => import('./views/presentation/categoryBesoin'));

// // auth
const LoginCollection = lazy(() => import('./views/auth/LoginDS'));

const Error404 = lazy(() => import('./views/error/Error404'));

// // presentation

const ProductDetail = lazy(() => import('./views/presentation/ProductDetail'));
const Cart = lazy(() => import('./views/presentation/cart'));
const Checkout = lazy(() => import('./views/presentation/checkout'));

const SignIn = lazy(() => import('./views/auth/SignIn'));
const DashboardPresentation = lazy(() => import('./views/presentation/DashboardPresentation'));
const LogisticDashboard = lazy(() => import('./views/presentation/Logisitic'));
const DatabaseDashboard = lazy(() => import('./views/presentation/Database'));
const SecretariatDashboard = lazy(() => import('./views/presentation/Secretariat'));
const ComptabiliteDashboard = lazy(() => import('./views/presentation/Comptabilite'));
const AgisDashboard = lazy(() => import('./views/presentation/Agis'));
const DashboardTerrain = lazy(() => import('./views/presentation/DashboardTerrain'));
const LoginReport = lazy(() => import('./views/auth/LoginReport'));

export default function App() {
  const { cart, cartTotal, addToCart, removeFromCart, emptyCart, total } = useShopingCart();
  const { user, setUser } = useUser();
  const { fosa, setFosa } = useFosa();
  const { admin, setAdmin } = useAdmin();
  const { language, setLanguage } = useLanguage();
  const { devise, setDevise } = useDevise();
  return (
    <CartContext.Provider
      value={{
        cart,
        cartTotal,
        addToCart,
        removeFromCart,
        emptyCart,
        user,
        setUser,
        admin,
        setAdmin,
        language,
        setLanguage,
        devise,
        setDevise,
        total,
        fosa,
        setFosa
      }}
    >
      <HashRouter>
        <Suspense fallback={<ReactLoader />}>
          <Switch>
            {/* Product Pages - START */}
            {/* admin */}
            <Route exact path="/">
              <Redirect to={ROUTES.LOGIN_REPORT} />
            </Route>
            <Route
              path={ROUTES.DASHBOARD_LOGISTIC}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <LogisticDashboard className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_COMPTABILITE}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <ComptabiliteDashboard className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_DATABASE}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <DatabaseDashboard className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_SECRETARIAT}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <SecretariatDashboard className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_AGIS}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <AgisDashboard className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_REPORT}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <DashboardTerrain className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.LOGIN_REPORT}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <LoginReport className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.CATEGORY_BESOIN}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <CategoryBesoin className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />

            <Route
              path={ROUTES.CART}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <Cart className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.CHECKOUT}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <Checkout className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_PRESENTATION}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <DashboardPresentation className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.SIGNIN}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <SignIn className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.LOGIN}
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <LoginCollection className="rounded-2xl bg-white relative " />
                  </div>
                </>
              )}
            />

            <Route
              path="/"
              exact
              render={() => (
                <>
                  <div className="lg:px-10 py-5">
                    <Error404 />
                  </div>
                </>
              )}
            />
          </Switch>
        </Suspense>
      </HashRouter>
    </CartContext.Provider>
  );
}
