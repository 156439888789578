/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';

export default function useFosa() {
  const [fosa, setFosa] = useState(null);

  useEffect(() => {
    async function getUser() {
      const user = JSON.parse(localStorage.getItem('authFosa'));
      const authTime = localStorage.getItem('authTime');
      console.log('Fosa in hook fosa  ', user);
      console.log('Time in hook fosa ', authTime);
      if (user) {
        if (new Date().getTime() - new Date(authTime).getTime() < 1000 * 30 * 24 * 3600) {
          console.log('use set well', new Date(authTime));
          setFosa(user);
        } else setFosa(null);
      } else setFosa(null);
    }
    // console.log('User in hook use user ', userId);
    getUser();
  }, []);
  return { fosa, setFosa };
}
