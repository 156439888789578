/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import useDevise from './useDevise';

export default function useShopingCart() {
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [devise, setDevise] = useState(localStorage.getItem('devise'));

  const getPrice = (item) => {
    const _devise = localStorage.getItem('devise');
    setDevise(_devise);
    if (parseFloat(item.price) !== 0.0) return item.price;
    if (parseFloat(item.priceDollars) !== 0.0) return item.priceDollars;
  };

  const getTotalItemFC = (_cart) => {
    const arr = [];
    let total = parseFloat('0');
    for (let i = 0; i < _cart.length; i++) {
      if (parseFloat(_cart[i].price) !== 0.0) arr.push(_cart[i]);
    }
    for (let ii = 0; ii < arr.length; ii++) {
      total += parseFloat(getPrice(arr[ii]));
    }
    console.log('Total FC ', total);
    return total.toFixed(2);
  };

  const getTotalItemUSD = (_cart) => {
    const arr = [];
    let total = parseFloat('0');
    for (let i = 0; i < _cart.length; i++) {
      if (parseFloat(_cart[i].priceDollars) !== 0.0) arr.push(_cart[i]);
    }
    for (let ii = 0; ii < arr.length; ii++) {
      total += parseFloat(getPrice(arr[ii]));
    }
    console.log('Total Dollars ', total);
    return total;
  };

  const total = () => {
    const totalDollars = getTotalItemUSD(cart) !== 0.0 ? ` ${getTotalItemUSD(cart)}$` : '';
    const totalVal =
      getTotalItemFC(cart) !== 0.0
        ? `${getTotalItemFC(cart)}$ ${getTotalItemUSD(cart) !== 0.0 ? '&' : ''}`
        : '';
    const cartTot = totalVal + totalDollars;
    setCartTotal(cartTot);
    console.log('compute Total cart', `${cartTot}`);
  };
  useEffect(() => {
    total();
  }, [cart, devise]);

  const addToCart = (el) => {
    setCart([...cart, el]);
  };

  const removeFromCart = (el) => {
    let hardCopy = [...cart];
    hardCopy = hardCopy.filter((cartItem) => cartItem.productId !== el.productId);
    setCart(hardCopy);
  };
  const emptyCart = () => {
    setCart([]);
  };
  return { cart, cartTotal, addToCart, removeFromCart, emptyCart, total };
}
