export const PRESENTATION_BESOIN = '/besoin';
export const CATEGORY_BESOIN = '/besoin/:idDepartment';
export const LOGIN = '/login';
export const SIGNIN = '/signin';
export const CART = '/cart';
export const CHECKOUT = '/checkout';
export const DASHBOARD_PRESENTATION = '/mydashboard';
export const DASHBOARD_LOGISTIC = '/department/logistique';
export const DASHBOARD_SECRETARIAT = '/department/Secretariat';
export const LOGIN_REPORT = '/report_fosa';
export const DASHBOARD_REPORT = '/report_fosa_ds';
export const DASHBOARD_DATABASE = '/department/database';
export const DASHBOARD_AGIS = '/department/Administration';
export const DASHBOARD_COMPTABILITE = '/department/Comptabilite';
