/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';

export default function useAdmin() {
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    async function getAdmin() {
      const admin = JSON.parse(localStorage.getItem('authAdmin'));
      const authTime = localStorage.getItem('authAdminTime');
      console.log('admin in hook use user here ', admin);
      console.log('Time in hook use user here ', authTime);
      if (admin) {
        if (new Date().getTime() - new Date(authTime).getTime() < 1000 * 24 * 3600) {
          console.log('admin set well', new Date(authTime));
          setAdmin(admin);
        } else setAdmin(null);
      } else setAdmin(null);
    }
    // console.log('User in hook use user ', userId);
    getAdmin();
  }, []);
  return { admin, setAdmin };
}
