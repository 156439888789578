/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';

export default function useLanguage() {
  const [language, setLanguage] = useState('French');
  localStorage.setItem('language', 'French');
  useEffect(() => {
    async function getLanguage() {
      const _language = localStorage.getItem('language');
      if (_language) {
        setLanguage(_language);
      }
    }
    // console.log('User in hook use user ', userId);
    getLanguage();
  }, [language]);
  return { language, setLanguage };
}
