import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/styles/tailwind.min.css';
import './assets/styles/docs.css';

import App from './app';

ReactDOM.render(<App />, document.getElementById('root'));
